<template>
    <div
      class="
        card-hover
        d-flex
        justify-content-center
        align-items-center
        flex-column
      "
      :style="{height: height, width: width}"
    >
      <div
        class="papelera"
        @click="
          deleteFunction($event,data)
        "
      ></div>
      <router-link
        :to="to"
        @click="playFunction(data)"
        ><div class="flecha"></div
      ></router-link>
      <div
        class="lapiz"
        @click="
          editFunction($event,data)
        "
      ></div>
    </div>
    <div
      class="
        card-agregar
        d-flex
        justify-content-start
        align-items-center
        flex-column
      "
      :style="{height: height, width: width}"
    >
      <h1 class="titulo-cards">{{ data.title }}</h1>
      <h2 class="tipo-gestion">{{ data.type }}</h2>
      <h3 class="ultima-actualizacion">Ultima actualización</h3>
      <p class="date-card">{{ data.date }}</p>
    </div>
    <div 
      class="card-botones"
      :style="{height: height, width: width}"
    >

    </div>
</template>

<script>
    export default { 
        name: "Card3D", 
        props:{
          data: {
            type: Object,
            default: {
              title: String,
              type: String,
              date: String
            }
          }, 
          playFunction: {
            type: Function,
            default: () => {}
          },
          deleteFunction: {
            type: Function,
            default: () => {}
          }, 
          editFunction: {
            type: Function,
            default: () => {}
          }, 
          to: {
            type: String,
            default: ''
          },
          width: {
            type: String,
            default: ''
          }, 
          height: {
            type: String,
            default: ''
          }
        }
    }
</script>


<style scoped>
    .ultima-actualizacion,.titulo-cards,.tipo-gestion{
        font-family: Rubik;
        font-weight: bold;
        margin-top:40px ;
        margin-bottom:50px ;
        font-size: 55px;
        text-align: center;
    }
   .titulo-cards{
        font-family: Rubik;
        font-weight: bold;
        margin-top:40px ;
        margin-bottom:50px ;
        font-size: 55px;
        text-align: center;
    }
    .titulo-cards{
        font-size:40px ;
        margin-top:20px ;
        margin-bottom:0px ;
    }
    .tipo-gestion{
        font-size: 18px;
        margin-top: 0px;
        margin-bottom:0px ;
    }
    .ultima-actualizacion{
      position: absolute;
      font-size: 18px;
      width: 100%;
      bottom: 30px;
      margin: 25px;
    }
    .date-card{
      position: absolute;
      bottom: 30px;
      margin: 0px;
    }
    .card-hover{
        position: absolute;
        background: transparent;
        z-index: 20;
    }
    .card-hover > div{
        opacity: 0;
        transform: rotateY(180deg) rotateX(-2.5deg) scale(1);
        cursor: pointer;
    }
    .card-botones{
        position: absolute;
        background: linear-gradient(169.26deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
        border-radius: 16px;
        padding: 10px;
        height: 350px;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.25), inset 0 0 9px white;
        cursor: pointer;
        width: 85%;
        opacity: 0;
        transition: all 1s;
        z-index: 10;
    }
    .card-agregar{
        position: absolute;
        background: linear-gradient(169.26deg, rgba(255, 255, 255, 0.84) 0%, rgba(255, 255, 255, 0.15) 100%);
        border-radius: 16px;
        padding: 10px;
        height: 350px;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.25), inset 0 0 9px white;
        width: 85%;
        transition: all 1s;
        z-index: 10;
    }
    .card-hover:hover ~ .card-agregar{
        transform: rotateY(180deg) rotateX(-2.5deg);
        transition: all 1s;
    }
    .card-hover:hover ~ .card-botones{
        transform: rotateY(180deg) rotateX(-2.5deg);
        opacity: 1;
        transition: all 1s;
    }
    .card-hover:hover > div{
        opacity: 1;
        transform: rotateY(0deg) rotateX(-2.5deg);
        transition: all 1s;
    }

    .papelera{
        background-repeat: no-repeat;
        background-image: url(../assets/pictures/papelera.svg);
        width: 50px;
        height: 50px;
        transition: all 0.5s;
    }
    .flecha{
        background-repeat: no-repeat;
        background-image: url(../assets/pictures/flecha.svg);
        width: 50px;
        height: 50px;
        margin-top:50px ;
        transition: all 0.5s;
    }
    .lapiz{
        background-repeat: no-repeat;
        background-image: url(../assets/pictures/lapiz.svg);
        width: 50px;
        height: 50px;
        margin-top:50px ;
        transition: all 0.5s;
    }
</style>

<template>
    <div class="contenedor-profile d-flex align-items-center justify-content-start flex-column">
            <div class="fondo-degradado-profile" v-if="bulFormulario === false"></div>
            <h1 class="titulo-profile">Tus gestiones</h1>
            <div class="col-10">
               <div class="row d-flex flex-row justify-content-around">
                    <div class="col-3 card-estructura-mas"  @click="cambiarForm($event,0,0,'')">
                        <div class="card-agregar d-flex justify-content-start align-items-center flex-column">
                            <h1 class="iniciar-gestion">Iniciar gestión</h1>
                            <svg class="cruz-iniciar" viewBox="0 0 176 173" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_f_481_2)">
                                <path d="M72 102.5V162H104.5V102.5H165V70H104.5V11H72V70H11V102.5H72Z" stroke="black"/>
                                </g>
                                <path d="M72 102.5V162H104.5V102.5H165V70H104.5V11H72V70H11V102.5H72Z" fill="url(#paint0_linear_481_2)" stroke="black"/>
                                <defs>
                                <filter id="filter0_f_481_2" x="0.5" y="0.5" width="175" height="172" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur_481_2"/>
                                </filter>
                                <linearGradient id="paint0_linear_481_2" x1="11" y1="11" x2="165" y2="162" gradientUnits="userSpaceOnUse">
                                <stop stop-color="white"/>
                                <stop offset="1" stop-color="white" stop-opacity="0"/>
                                </linearGradient>
                                </defs>
                            </svg>
                        </div>
                    </div>
                    <div
                        class="col-3 card-estructura"
                        v-for="gestion in gestiones"
                        v-bind:key="gestion.id"
                        v-bind:value="gestion"
                    > 
                        <Card3D 
                            :data="{
                                title:gestion.Nombre_gestion, 
                                type: gestion.Tipo_gestion, 
                                date: gestion.Fecha_actualizacion,
                            }" 
                            :deleteFunction="(e) => cambiarForm(e, 1, gestion.Id_gestion, gestion.Nombre_gestion)" 
                            :editFunction="(e) => cambiarForm(e, 2, gestion.Id_gestion, gestion.Nombre_gestion)"
                            :playFunction="() => entrarEnGestion(gestion.Id_gestion)"
                            to="/dashboard/estadistica"
                            width="80%" 
                            height="350px"
                        />
                    </div>                    
               </div>
           </div>
    </div>
    <div class="contenedor-agregar-gestion d-flex align-items-center justify-content-start flex-column" v-if="bulFormulario === true">
        <div class="x-nuevo" @click="cambiarForm($event,0,0,'')"></div>
        <div class="contenedor-form2 d-flex align-items-center justify-content-start flex-column" v-if="bulFormulario2 === 0">
            <h1 class="titulo-agregar-gestion">¡Inicia tu gestión!</h1>
            <form class="d-flex flex-wrap flex-column flex-lg-row align-items-center justify-content-lg-around margin-responsive" style="width:100%;" v-on:submit.prevent v-if="bulLoader === false">
                <div class="col-11 col-sm-8 col-lg-5">
                    <label for="nombre" class="label">Nombre gestión</label>
                    <input type="text" id="nombre" class="input" v-model="nombreGestion" />
                </div>
                <div class="col-11 col-sm-8 col-lg-5">
                    <label for="rif" class="label">RIF</label>
                    <input type="text" id="rif" class="input" v-model="rifGestion" />
                </div>
                <div class="col-11 col-sm-8 col-lg-5">
                    <label for="correo" class="label">Correo</label>
                    <input type="text" id="correo" class="input" v-model="correoGestion" />
                </div>
                <div class="col-11 col-sm-8 col-lg-5">
                    <label for="tipo" class="label">Tipo de gestión</label>
                    <select name="" id="tipo" class="input" v-model="tipoGestion">
                            <option value="" ></option>
                            <option v-for="tipos in tipo" v-bind:key="tipos.id" v-bind:value="tipos">{{tipos}}</option>
                    </select>
                </div>
                <button class="button-gestion2 d-flex justify-content-center align-items-center" @click="crearGestion($event)">Crear</button>
            </form>
            <div class="loader" v-if="bulLoader === true">
                <div class="caja"><div class="caja2"></div></div>
            </div>
            <div class="error-profile d-flex justify-content-center align-items-center" v-if="bul === true">
                <div><p>{{mensaje}}</p></div>
            </div>  
            <div class="correcto-profile d-flex justify-content-center align-items-center" v-if="bul1 === true">
                <div><p>{{mensaje}}</p></div>
            </div> 
        </div>
        <div class="contenedor-form d-flex align-items-center justify-content-start flex-column" v-if="bulFormulario2 === 1">
            <h1 class="titulo-agregar-gestion">¡Seguro que quieres eliminar tu gestion?</h1>
            <form class="form-gestion d-flex align-items-start justify-content-center flex-column" v-on:submit.prevent v-if="bulLoader === false">
                <label for="nombre" class="label d-flex flex-row">Escribe <p class="nombre-gestion">{{this.nombreGestionEliminar}}</p> para confirmar la acción</label>
                <input type="text" id="nombre" class="input" v-model="palabraConfirmadora" />
                <button class="button-gestion d-flex justify-content-center align-items-center" @click="eliminarGestion($event)">Eliminar</button>
            </form>
            <div class="loader" v-if="bulLoader === true">
                <div class="caja"><div class="caja2"></div></div>
            </div>
            <div class="error-profile d-flex justify-content-center align-items-center" v-if="bul === true">
                <div><p>{{mensaje}}</p></div>
            </div>  
            <div class="correcto-profile d-flex justify-content-center align-items-center" v-if="bul1 === true">
                <div><p>{{mensaje}}</p></div>
            </div> 
        </div>
        <div class="contenedor-form d-flex align-items-center justify-content-start flex-column" v-if="bulFormulario2 === 2">
            <h1 class="titulo-agregar-gestion">Editar gestion</h1>
            <form class="d-flex flex-wrap flex-column flex-lg-row align-items-center justify-content-lg-around margin-responsive" style="width:100%;" v-on:submit.prevent v-if="bulLoader === false">
                <div class="col-11 col-sm-8 col-lg-5">
                    <label for="nombre" class="label">Nombre gestión</label>
                    <input type="text" id="nombre" class="input" v-model="nombreGestionEditar" />
                </div>
                <div class="col-11 col-sm-8 col-lg-5">
                    <label for="rif" class="label">RIF</label>
                    <input type="text" id="rif" class="input" v-model="rifGestionEditar" />
                </div>
                <div class="col-11 col-sm-8 col-lg-5">
                    <label for="correo" class="label">Correo</label>
                    <input type="text" id="correo" class="input" v-model="correoGestionEditar" />
                </div>
            </form>
            <button class="button-gestion2 d-flex justify-content-center align-items-center" @click="editarGestion($event)" v-if="bulLoader === false">Editar</button>
            <div class="loader" v-if="bulLoader === true">
                <div class="caja"><div class="caja2"></div></div>
            </div>
            <div class="error-profile d-flex justify-content-center align-items-center" v-if="bul === true">
                <div><p>{{mensaje}}</p></div>
            </div>  
            <div class="correcto-profile d-flex justify-content-center align-items-center" v-if="bul1 === true">
                <div><p>{{mensaje}}</p></div>
            </div> 
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions} from "vuex"
    import axios from 'axios'
    import Card3D from '../../components/Card3D.vue'

    export default {
        name: "App",
        components: {
            Card3D
        },
        data(){
            return{
                //control de respuestas de la api
                bul:false,
                bul1:false,
                mensaje:'',

                //informacion formulario
                gestiones: this.gestiones = {},
                nombreGestion: '',
                tipoGestion: '',
                rifGestion: '',
                correoGestion: '',
                tipo: this.tipo = [
                    "personal",
                    "empresarial"
                ],

                //condicional formulario
                bulFormulario: false,
                bulFormulario2:0,

                //informacion de editar y eliminar gestiones
                id: '',
                nombreGestionEliminar:'',
                palabraConfirmadora: '',
                nombreGestionEditar: '',
                bulLoader: false
            }
        },

        methods: {

            //entrar a gestion por id

            async entrarEnGestion(id){
                let Tokengestion = await axios.post(`${this.server}/information/crearTokenGestion`, {
                    id:id,
                    token: localStorage.getItem('token')
                })
                //guardamos token en local storage
                localStorage.setItem('token2',Tokengestion.data.token)
            },

            //actualizar gestiones

            async loadProfile(){
                //consulta de apirest
                let userProfile = await axios.get(`${this.server}/information/consultaProfile`, {

                    params: {token: localStorage.getItem('token')}}, 

                    {headers: {'Content-Type': 'application/json'}
                });

                //token expiro
                if(userProfile.data.message === "Error en validación"){
                    this.$router.push({path: '/login'})
                }
                
                //impresion de datos
                this.gestiones = [...userProfile.data.managements]

            },

            //abre el formulario

            cambiarForm(e,tipo,id,nombre){
                if(tipo === 0){
                    //nueva gestion
                    if(e.target.className === 'x-nuevo'){
                        this.bulFormulario = false
                        this.bulFormulario2 = 0
                        this.nombreGestion = ''
                        this.nombreGestionEditar = ''
                        this.tipoGestion = ''
                        this.tipoGestionEditar = ''
                        this.id = ''
                        this.palabraConfirmadora = ''
                        this.bulLoader = false
                    }else{
                        this.bulFormulario = true
                        setTimeout(() => {
                            document.children[0].children[1].children[1].children[2].style.cssText = `height:${document.children[0].children[1].children[1].clientHeight}px;`
                            window.scroll(0,0)
                        }, 20);
                    }
                }else{
                    //administrar datos de gestion
                    this.bulFormulario2 = tipo
                    this.bulFormulario = true
                    this.id = id
                    this.nombreGestionEliminar = nombre
                    setTimeout(() => {
                        document.children[0].children[1].children[1].children[2].style.cssText = `height:${document.children[0].children[1].children[1].clientHeight}px;`
                        window.scroll(0,0)
                    }, 20);
                }
            },

            //funcion creadora de las gestiones

            async crearGestion(){
                //comprobando que los campos no esten vacios
                if(this.nombreGestion === '' || this.tipoGestion === ''){
                    this.swift('rellena todo los campos porfaror', 1)
                    return
                }

                //comprobando que el nombre no exceda 30 caracteres

                if(this.nombreGestion.length > 10){
                    this.swift('nombre gestión no puede exceder los 10 caracteres', 1)
                    return
                }

                //creando la gestion

                let gestion = await axios.post(`${this.server}/information/crearGestion`, {
                    nombreGestion: this.nombreGestion,
                    tipoGestion: this.tipoGestion,
                    rifGestion: this.rifGestion,
                    correoGestion: this.correoGestion,
                    token: localStorage.getItem('token')
                })
                
                if(gestion.data.message === "Gestion creada"){
                    //password cambiada
                    this.swift('Gestion creada con exito',2)
                }
                if(gestion.data.message === "Error en validación"){
                    //se espiro la url
                    this.swift('tiempo excedido para cambio de contraseña',1)
                }
            },
            
            //funcion para eliminar gestiones

            async eliminarGestion(){
                //comprobando que los campos no esten vacios
                if(this.palabraConfirmadora === ''){
                    this.swift('rellena todo los campos porfaror', 1)
                    return
                }

                //comprobando que el input coincida con el nombre de la gestion


                if(this.palabraConfirmadora === this.nombreGestionEliminar){

                    //eliminando gestion

                    let gestion = await axios.post(`${this.server}/information/eliminarGestion`, {
                        id:this.id,
                        token: localStorage.getItem('token')
                    })
                    
                    if(gestion.data.message === "Gestion eliminada"){
                        //password cambiada
                        this.swift('Gestion eliminada con exito',2)
                    }
                    if(gestion.data.message === "Error en validación"){
                        //se espiro la url
                        this.swift('tiempo excedido para cambio de contraseña',1)
                    }
                }else{
                    this.swift('el campo no coincide', 1)
                    return
                }
            },

            //funcion para editar gestiones

            async editarGestion(){
                //comprobando que los campos no esten vacios
                if(this.nombreGestionEditar === '' || this.rifGestionEditar === '' || this.correoGestionEditar === ''){
                    this.swift('rellena todo los campos porfaror', 1)
                    return
                }

                //comprobando que el nombre no exceda 30 caracteres

                if(this.nombreGestionEditar.length > 30){
                    this.swift('nombre gestión no puede exceder los 30 caracteres', 1)
                    return
                }

                //editar gestion

                let gestionEditar = await axios.post(`${this.server}/information/editarGestion`, {
                    nombreGestionEditar: this.nombreGestionEditar,
                    rifGestionEditar: this.rifGestionEditar,
                    correoGestionEditar: this.correoGestionEditar,
                    id: this.id,
                    token: localStorage.getItem('token')
                })
                
                if(gestionEditar.data.message === "Gestion editada"){
                    //password cambiada
                    this.swift('Gestion editada con exito',2)
                }
                if(gestionEditar.data.message === "Error en validación"){
                    //se espiro la url
                    this.swift('tiempo excedido para cambio de contraseña',1)
                }
            },

            //funcion encargada de quitar la caja de error

            quitarError(){
                this.bul = false
                this.mensaje = ''
            },

            //funcion encargada de quitar la caja de correcto

            quitarHecho(){
                this.bul1 = false
                this.mensaje = ''
                this.bulLoader = true
                setTimeout(() => {
                    this.loadProfile();
                    this.bulFormulario = false
                    this.bulFormulario2 = 0
                    this.nombreGestion = ''
                    this.nombreGestionEditar = ''
                    this.tipoGestion = ''
                    this.tipoGestionEditar = ''
                    this.id = ''
                    this.palabraConfirmadora = ''
                    this.correoGestionEditar = ''
                    this.rifGestionEditar = ''

                    this.bulLoader = false
                }, 2000);
            },

            //swift entre error y correcto

            swift(mensaje, caso){
                if(caso === 1){
                    this.bul = true
                    this.mensaje = mensaje
                    setTimeout(() => {
                        this.quitarError()
                    }, 1500);
                }else{
                    this.bul1 = true
                    this.mensaje = mensaje
                    setTimeout(() => {
                        this.quitarHecho()
                    }, 1500);
                }
            }
        },

        created () {
            this.loadProfile();
        },
        computed:{
            ...mapState(["token", "server"])
        }
    }
</script>

<style scoped>
    .loader{
        position: relative;
        width: 200px;
        height: 200px;
        perspective: 300px;
        top:100px;
    }
    .caja{
        position: absolute;
        width: 80%;
        height: 80%;
        background: #110133;
        border-radius:10%;
        left: 10%;
        top: 10%;
        animation: loaderA 3s ease-in-out infinite;
        perspective: 300px;
    }
    .caja2{
        width: 50%;
        height: 50%;
        background: #297F87;
        border-radius:50%;
        margin-left: 25%;
        margin-top: 25%;
        animation: loaderB 3s ease-in-out infinite;
    }

    @keyframes loaderA {
        0%{
            transform: rotateZ(0deg) rotateY(0deg);
            border-radius:10% ;
        }
        50%{
            transform: rotateZ(180deg) rotateY(180deg);
            border-radius:50% ;
        }
        100%{
            transform: rotateZ(360deg) rotateY(360deg);
            border-radius:10% ;
        }
    }

    @keyframes loaderB {
        0%{
            transform: rotateZ(0deg) rotateY(0deg);
            border-radius:50% ;
        }
        50%{
            transform: rotateZ(180deg) rotateY(180deg);
            border-radius:10% ;
        }
        100%{
            transform: rotateZ(360deg) rotateY(360deg);
            border-radius:50% ;
        }
    }

    .form-gestion{
        width: 50%;
    }
    .nombre-gestion{
        color:#297F87;
        margin-left: 5px;
        margin-right:5px;
    }
    .contenedor-agregar-gestion{
        position: absolute;
        top:0px;
        left: 0px;
        width: 100%;
        z-index: 10;
        backdrop-filter:blur(2px);
        background:linear-gradient(240.98deg, rgba(0, 145, 142, 0) 45.71%, #00918E 100%),linear-gradient(290.11deg, rgba(17, 1, 51, 0.46) 0%, rgba(17, 1, 51, 0) 52.11%),linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
    }
    .x-nuevo{
        position: absolute;
        top:0px;
        width: 27.5px;
        height: 27.5px;
        background-repeat: no-repeat;
        background-image: url(../../assets/pictures/X.svg);
        transition: all 0.5s;
        margin-top:135px;
        right: 12.5%;
        z-index: 20;
        cursor: pointer;
    }
    .titulo-agregar-gestion{
        font-family: Rubik;
        font-weight: bold;
        margin-top:40px ;
        font-size: 45px;
        text-align: center;
    }
    .button-gestion,.button-gestion2{
        width: 50%;
        height: 40px;
        border:none;
        border-radius:10px;
        background: #297F87;
        color: white;
        text-align: center;
        font-family: Rubik;
        font-size:20px ;
        margin-left: 25%;
        margin-top: 80px;
    }
    .button-gestion2{
        margin-left: 0px;
    }
    .label{
        font-family: Rubik;
        margin-top: 75px;   
        font-weight:bold ;
    }
    .error-profile{
        margin: 1.5em 0em 1.5em 0em;
        padding-top: 1em;
        width: 700px;
        background-color: rgb(128, 18, 22,0.75);
        color: white;
        text-align: center;
        font-family: Rubik;
        font-weight: bold;
    }
    .correcto-profile{
        margin: 1.5em 0em 1.5em 0em;
        padding-top: 1em;
        width: 700px;
        background-color: rgb(11, 114, 40,0.75);
        color: white;
        text-align: center;
        font-family: Rubik;
        font-weight: bold;
    }
    .input{
        border-radius:10px;
        border: #6464646b 2px solid;
        width: 100%;
        padding-left: 10px;
        padding-top: 3px;
        padding-bottom: 3px;
    }
    .contenedor-form,.contenedor-form2{
        position: absolute;
        top:0px;
        left: 10%;
        width: 80%;
        margin-top:100px;
        background: rgba(255, 255, 255, 0.75);
        height: 600px;
        backdrop-filter:blur(2px);
        border-radius: 40px;
    }
    .contenedor-form2{
        margin-top:50px;
    }
    .contenedor-profile{
        position: relative;
        height: auto;
        width: 100%;
        padding-bottom:20px ;
        padding-top:100px ;
    }
    .card-estructura, .card-estructura-mas{
        margin-top:35px ;
        position: relative;
        height: 350px;
        perspective: 500px;
    }
    .card-estructura-mas{
        cursor: pointer;
    }
    .card-agregar{
        position: absolute;
        background: linear-gradient(169.26deg, rgba(255, 255, 255, 0.84) 0%, rgba(255, 255, 255, 0.15) 100%);
        border-radius: 16px;
        padding: 10px;
        height: 350px;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.25), inset 0 0 9px white;
        width: 85%;
        transition: all 1s;
    }
    .fondo-degradado-profile{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        background:linear-gradient(290.11deg, rgba(17, 1, 51, 0.26) 0%, rgba(17, 1, 51, 0) 52.11%), linear-gradient(248.49deg, rgba(255, 255, 255, 0) 41.48%, rgba(41,127,135,0.5) 100.24%) ;
        z-index: -10;
        height: calc(100% + 300px);
    }
    .titulo-profile,.iniciar-gestion{
        font-family: Rubik;
        font-weight: bold;
        margin-top:40px ;
        margin-bottom:50px ;
        font-size: 55px;
        text-align: center;
    }
    .iniciar-gestion{
        font-size:40px ;
        margin-top:20px ;
        margin-bottom:0px ;
    }
    .cruz-iniciar{
        width: 60%;
        margin-top:40px ;
    }
</style>